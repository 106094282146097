<template>
  <a-modal title="应标意向企业" width="1200px" v-model="visible" :footer="null">
    <!-- <div class="search-box">
      <div class="input-label">企业名称：</div>
      <a-input placeholder="请输入企业名称" v-model="enterpriseName" style="width: 220px; margin-right: 24px"></a-input>
      <a-button type="primary" @click="$refs.table.initPage()" >搜索</a-button>
    </div> -->
    <div class="table-box">
      <p-table
        ref="table"
        :sourceData="getDataApi"
        :columns="columns"
        rowKey="id"
        :scroll="{ y: 100 }"
        :pagination="false"
        extraHeight="500"
      >
        <template slot="operation" slot-scope="text, record">
          <div class="operation_btn" @click="$refs.lookDetails.show(record)" v-perms:look="'details'">查看</div>
          <div class="operation_btn" @click="$refs.wBidder.show(record)" v-if="[0,4].includes(record.status)" v-perms:look="'confirm'">确认合作</div>
          <div class="operation_btn" v-else style="color: #cccccc;" >已确认合作</div>
        </template>
      </p-table>
    </div>
    <lookDetails ref="lookDetails" />
    <wBidder ref="wBidder" @success="$refs.table.initPage()" />
  </a-modal>
</template>

<script>
import { queryListByCondition } from '@/api/TenderingBidding'
import lookDetails from './look-details.vue'
import wBidder from './w-bidder.vue'

export default {
  name: 'TableModal',
  components: {
    lookDetails,
    wBidder
  },
  data () {
    return {
      enterpriseName: '',
      id: '',
      visible: false,
      columns: [
        {
          title: '序号',
          dataIndex: 'key',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '企业名称',
          key: '1',
          dataIndex: ['enterpriseInfo', 'enterpriseName']
        },
        {
          title: '统一社会信用代码',
          key: '2',
          dataIndex: ['enterpriseInfo', 'creditCode']
        },
        {
          title: '企业类型',
          key: '3',
          dataIndex: ['enterpriseInfo', 'enterpriseType']
        },
        {
          title: '企业规模',
          key: '4',
          dataIndex: ['enterpriseInfo', 'enterpriseScale']
        },
        {
          title: '成立日期',
          key: '5',
          dataIndex: ['enterpriseInfo', 'establishmentTime']
        },
        {
          title: '企业介绍',
          key: '6',
          dataIndex: ['enterpriseInfo', 'description']
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ]
    }
  },
  methods: {
    show (id) {
      this.id = id
      this.visible = true
      this.$nextTick(() => {
        this.$refs.table.initPage()
      })
    },
    handleLook (row) {},
    handleConfirm () {},
    getDataApi (params) {
      if (this.id) {
        return queryListByCondition(this.id, this.enterpriseName)
      } else {
        return Promise.resolve({})
      }
    }
  }
}
</script>

<style lang="less" scoped>
.search-box {
  display: flex;
  align-items: center;
  padding-bottom: 24px;
}

.table-box {
  // padding-bottom: 50px;
}
</style>
